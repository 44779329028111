<template>
  <div class="project-title">
    <div :class="'project-title__header-'+projectStatusCode"
         class="project-title__header">
      <div class="project-title__title">{{ projectTitle }}</div>
    </div>
    <slot class="project-title__content"></slot>
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import {mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapGetters('OneProjectStore', ['project']),
      projectId() { return this.project.id; },
      waitingForCustomer() { return this.project?.waitingForCustomer; },
      projectStatusCode() {
        if (this.waitingForCustomer) return 'waiting_for_customer';
        else if (this.project.isXTRF) return 'quote_accepted';
        else return this.project.status;
      },
      jobLocked() {
        return this.storeJobObj.locked;
      },
      projectStatusObj() {
        return helpers.getJobInfo.titleJobStatusObj({
          context: this,
          projectStatusCode: this.projectStatusCode,
          cancellerName: this.cancellerName,
          cancelledAt: this.cancelledAt,
          jobLocked: this.locked,
        }) || {};
      },
      projectStatus() {
        return helpers
          .getProjectInfo
          .getProjectStatus(
            this,
            {status: this.projectStatusCode}
          );
      },
      projectTitle() {
        return `#${this.projectId} - ${this.projectStatus}`;
      },
      cancellerName() {
        return 'cancellerName'; // this.$store.state.OneAssignmentStore.storeJobObj?.cancellerName || '';
      },
      cancelledAt() {
        return 'cancelledAt'; // this.$moment(this.$store.state.OneAssignmentStore.storeJobObj?.cancelledAt).format('DD.MM.YYYY HH:mm') || '';
      },
      feedbackText() {
        return this.$pgettext('translation', 'Feedback'); // this.$moment(this.$store.state.OneAssignmentStore.storeJobObj?.cancelledAt).format('DD.MM.YYYY HH:mm') || '';
      }
    }
  };
</script>

<style scoped>
.project-title__header {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 30px;
}

.project-title__header.assignment__yellow-status,
.project-title__header-waiting_for_customer {
  background-color: #ffe1c4;
  color: #f93;
}

.project-title__header.assignment__green-status {
  background-color: #b2dadc;
  color: #008489;
}

.project-title__header.assignment__dark-blue-status {
  background-color: #99afbb;
  color: #003855;
}

.project-title__header.assignment__purple-status {
  background-color: #bfbece;
  color: #2b255c;
}

.project-title__header-quote_requested,
.project-title__header-quote_sent,
.project-title__header-quote_accepted,
.project-title__header-quote_rejected {
  background-color: #99afbb;
  color: #2b255c;
}

.project-title__header-in_progress {
  background-color: #b2dadc;
  color: #008489;
}

.project-title__header-finished,
.project-title__header-completed {
  background-color: #bfbece;
  color: #2b255c;
}

.project-title__header-cancelled {
  background-color: #ffccda;
  color: #f04;
}

.project-title__header.assignment__red-status {
  background-color: #ffccda;
  color: #f04;
}

.project-title__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.11;
}

.project-title__content {
  margin-top: 0;
  padding: 0 10px;
}
</style>
